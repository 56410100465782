<template>
  <div class="container" v-if="$store.state.metamask.available && $store.state.currentNetwork">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="row">
            <div class="col-2">
              <img src="/images/metamask.svg" class="img-fluid" />
            </div>
            <div class="col-8">
              <form onsubmit="return false;">
                <div class="mb-3">
                  <div class="card-body">
                    <h5 class="card-title">Your SmartBCH address (Metamask)</h5>
                    <input class="form-control" type="text" id="SmartBCHAccount" disabled v-bind:value="$store.state.metamask.address" />
                    <div class="form-text">This is where your NFTs will go to!</div>
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">WAIFU contract</h5>
                    <div class="input-group">
                      <input class="form-control" type="text" id="CAaddress" v-bind:value="$store.state.currentNetwork.contract" aria-describedby="copy-button-ca" />
                      <button class="btn btn-outline-secondary" id="copy-button-ca" @click="copyAddress('CAaddress', $event)">Copy</button>
                    </div>
                    <div class="form-text">This is the token address. Add it to Metamask!</div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-2">

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" id="bch-slp">
        <div class="card mb-3">
          <div class="row">
            <div class="col-lg-2 col-md-12 qr-image d-none d-sm-none d-md-block">
              <div class="card-body">
                <h5 class="card-title">BCH address QR</h5>
                <img v-bind:src="$store.state.bridge.BCHQR" id="qr" alt="QR code" class="img-fluid" />
              </div>
            </div>
            <div class="col-lg-8 col-md-12">
              <div class="card-body">
                <h5 class="card-title">Your SLP Bridge address</h5>
                <form onsubmit="return false;">
                  <div class="mb-3 alert alert-warning">
                    Warning: Fees apply. Sending NFTs to {{ $store.state.currentNetwork.name }} costs {{ $store.state.currentNetwork.feeDisplay }} BCH for each NFT.<br/>
                    Send funds to the generated SLP address below.<br/><br/>
                    Do not send other NFTs to this address!
                  </div>
                  <div class="mb-3 input-group">
                    <label class="form-label">Bridge addresses (BCH & SLP formats)</label>
                    <div class="input-group">
                      <input class="form-control" type="text" id="BCHAccount" v-bind:value="$store.state.bridge.BCHAddress" aria-describedby="copy-button" />
                      <button class="btn btn-outline-secondary" id="copy-button" @click="copyAddress('BCHAccount',$event)">Copy</button>
                    </div>
                    <div class="input-group">
                      <input class="form-control" type="text" id="BCHAccountSLP" v-bind:value="$store.state.bridge.SLPAddress" aria-describedby="copy-button-slp" />
                      <button class="btn btn-outline-secondary" id="copy-button-slp" @click="copyAddress('BCHAccountSLP',$event)">Copy</button>
                    </div>
                    <div class="form-text">Send <strong>NFTs</strong> and <strong>funds</strong> to these addresses.</div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Balance</label>
                    <input class="form-control" type="text" id="BCHBalance" disabled v-bind:value="balance" />
                    <div class="form-text">Current balance.</div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-2 col-md-12 d-none d-sm-none d-md-block">
              <div class="card-body">
                <h5 class="card-title">SLP address QR</h5>
                <img v-bind:src="$store.state.bridge.SLPQR" id="qr-slp" alt="QR code" class="img-fluid" />
                <br/>
                <br/>
                <img src="/images/slp.png" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 app-title text-center">
        <h1>NFTs on Bridge address</h1>
      </div>
      <div class="d-flex justify-content-center">
        <button id="reloadNFTs" class="btn btn-primary" @click="loadNFTs()">Reload NFT overview</button>
      </div>
    </div>
    <div class="row d-flex justify-content-center" v-if="$store.state.metamask.connected">
      <div v-for="nft in nfts" :key="nft.id" v-bind:class="'col-lg-2 col-md-3 col-sm-6 nft-card ' + nft.statusClass">
        <div class="card text-center">
          <div class="card-body">
            <p class="card-text">
              <img v-bind:src="nft.image" class="img-fluid nft-image"/>
            </p>
            <p class="card-text">{{ nft.name }} <br/>#{{ nft.id }}</p>
            <p class="card-text">{{ nft.status }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      apiUrl: 'http://192.168.2.14:9011',
      copyCAButtonLabel: 'Copy',
      copySLPButtonLabel: 'Copy',
      copySBCHButtonLabel: 'Copy',
      balance: 0,
      nfts: [],
    }
  },
  methods : {
    copyAddress: function (elem, event) {
      var copyText = document.getElementById(elem);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      if (typeof navigator.clipboard !== 'undefined') {
        navigator.clipboard.writeText(copyText.value);
        document.execCommand("copy");
        event.target.innerHTML = 'Copied!';
      }
      return false;
    },
    loadAddress: function() {
      if(this.$store.state.metamask.address === null) {
        return;
      }
      if(this.$store.state.currentNetwork === null) {
        return;
      }

      fetch(this.apiUrl + '/address.php?addr=' + this.$store.state.metamask.address + '&network=' + this.$store.state.currentNetwork.short).then((response) => {
        return response.json();
      }).then((details) => {
        this.$store.commit('BRIDGE', {
          BCHAddress: details.address,
          SLPAddress: details.slp_address,
          BCHQR: details.qr,
          SLPQR: details.slpqr,
        })
      });
    },
    loadNFTs: function() {
      if(this.$store.state.metamask.address === null) {
        return;
      }
      if(this.$store.state.currentNetwork === null) {
        return;
      }

      this.balance = 'Loading...';
      fetch(this.apiUrl + '/wallet.php?addr=' + this.$store.state.metamask.address + '&network=' + this.$store.state.currentNetwork.short).then((response) => {
        return response.json();
      }).then((data) => {
        this.nfts = data.nfts;
        this.balance = data.balance;
      });
    }
  },
  mounted() {
    this.loadAddress();
    this.loadNFTs();
  },
  computed: {
    address() {
      return this.$store.state.metamask.address;
    },
    currentNetwork() {
      return this.$store.state.currentNetwork;
    }
  },
  watch: {
    address() {
      this.loadAddress();
      this.loadNFTs();
    },
    currentNetwork() {
      this.loadAddress();
      this.loadNFTs();
    }
  }
}
</script>
